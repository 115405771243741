.future-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  .future-heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .future-info {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .future-image-row {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .future-image {
    width: 250px; /* Decreased size significantly */
    gap: 40px;
    padding: 10px;
    height: auto;
    cursor: pointer;
  }
  