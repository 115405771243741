/* landing.css */

.landing {
  display: flex;
  height: 100vh;
  width: 102%;
  margin-top: -1px;
  margin-left: -10px;
  position: relative;
  overflow: hidden;

}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./background.svg'); /* Ensure the path is correct */
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: -1;
}

.right-image {
  flex: 1;
  background-image: url('./cover.svg'); /* Ensure the path is correct */
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 60px;
  color: white;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for better text contrast */
  backdrop-filter: blur(10px);
  text-align: left;
  z-index: 1;
}

.right-content h1 {
  font-size: 5em;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.right-content p {
  font-size: 1.2em;
  margin-bottom: 30px;
  line-height: 1.5;
}

.right-content button {
  padding: 15px 30px;
  font-size: 1em;
  color: white;
  background-color: #000000;
  border-radius: 10%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 5px;
}

.right-content button:hover {
  background-color: #e64a19;
  transform: scale(1.05);
}
