/* NavBar.css */
.nav {
  display: flex;
  z-index: 0;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  color: white;
  width: 100%;
  margin-left: -9px;
  margin-top: -10px;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.logo {
  height: 50px;
}

.nav-links {
  display: flex;
  align-items: center;
  margin-left: auto; /* Align links to the right */
  color: white;
  gap: 80px;
  margin-right: 100px;
}

.nav-links li {
  list-style: none;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    padding: 10px;
  }
  
  .logo {
    margin-bottom: 10px;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .nav-links a {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
