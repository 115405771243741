.app {
    text-align: center;
  }
  
  .image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  .image-row img {
    max-width: 30%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .image-row img:hover {
    transform: scale(1.1);
  }
  
  .heading {
    font-size: 4em;
    margin-top: 50px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 300;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .heading:hover {
    color: #555;
  }
  
  .info {
    font-size: 3em;
    margin-top: 10px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
    color: #666;
    transition: color 0.3s ease;
  }
  
  .info:hover {
    color: #888;
  }
  
  .glassmorphism {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
  }
  